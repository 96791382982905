/*******************
 *
 * TITLE
 *
*******************/

.page-grid-title {
    /* nothing here yet */
}

.page-grid-title .page-grid-add-btn {
    margin-top: 17px;
}

/*******************
 *
 * GENERAL
 *
*******************/

.table-bordered > thead > tr > th {
    background: #fff !important;
}

table.dataTable {
    margin: 0 0 20px 0;
}

table.dataTable thead th {
    outline: none;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
    float: right;
    cursor: pointer;
    font-family: 'Glyphicons Halflings';
    opacity: 0.2
}

table.dataTable thead .sorting:after {
    content: "\e150";
}

table.dataTable thead .sorting_asc:after {
    content: "\e155";
}

table.dataTable thead .sorting_desc:after {
    content: "\e156";
}

table.dataTable th:last-child,
table.dataTable td:last-child {
    text-align: center;
}

.dataTables_processing {
    display: none !important;
}

table.dataTable tbody tr td {
    padding-top: 12px;
}

table.dataTable tbody tr td:last-child {
    padding-top: 8px;
}

/**
 * Remove the default individual filters
 */
table.dataTable tfoot {
    display: none;
}

/**
 * Remove the default global searching filter
 */
.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_wrapper select,
.dataTables_wrapper input {
    background: #FFFFFF;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: inline-block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
}

.dataTables_length select {
    width: 60px;
    margin: 0 5px;
    height: 25px;
    padding: 0 5px;
}

.dataTables_filter {
    display: inline-block;
}

.dataTables_filter input {
    width: 150px;
    margin: 0 5px;
}

.dataTables_processing {
    margin: 0 0 20px 0;
}

/*******************
 *
 * ACTIONS BUTTONS
 *
*******************/

.dataTable td a.btn {
    padding: 3px 10px;
    margin-left: -1px;
    border-radius: 0;
    border: 1px solid #ddd !important;
    background: inherit;
    color: #676a6c !important;
    text-transform: capitalize;
    text-align: center;
    outline: none;
}


.dataTable td a.btn:hover {
    background: #fff;
}

.dataTable td a.btn:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dataTable td a.btn:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

/*******************
 *
 * PAGINATION
 *
*******************/

.dataTables_length {
    float: none !important;
    display: inline-block;
}

.dataTables_info {
    display: inline-block;
    float: right;
}

.dataTables_paginate {
    text-align: right;
}

.dataTables_paginate a {
    margin-left: -1px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    color: #676a6c;
    outline: none;
}

.dataTables_paginate a:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dataTables_paginate a:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.dataTables_paginate span a:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dataTables_paginate span a:last-child {
    border-top-right-radius: 0;
    border-bottom-rigth-radius: 0;
}

.dataTables_paginate a.current {
    background-color: #fff;
    border-color: #ddd;
}

.dataTables_paginate a.current:hover {
    background-color: inherit;
}

.dataTables_paginate a.disabled {
    color: #ddd;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.dataTables_paginate span.ellipsis {
    padding: 0 10px;
}


/*******************
 *
 * FILTERS
 *
*******************/

.page-grid-filters {
    width: 100%;
    background: #fff;
    padding: 20px;
    margin: 0 0 20px 0;
    border-top: 2px solid #e7eaec;
}

.grid-filters-row {
    padding: 10px 0;
}

.grid-filters-buttons .btn {
    margin: 0 5px 0 0;
}

/*******************
 *
 * MEDIA QUERIES
 *
*******************/

@media screen and (max-width: 991px){
    .grid-filter-field {
        padding-bottom: 10px;
        padding-top: 10px;
    }
}