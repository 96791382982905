/*******************
 *
 * GENERAL
 *
*******************/

.chosen-container-single:not(.chosen-container-active) .chosen-search-input {
  display: none;
}

.chosen-container .chosen-results li.disabled-result {
  color: #999;
}

.price-align-individual-order {
    width: 150px;
}

.page-grid-print-btn {
  margin: 0;
  position: relative;
  top: 10px;
}

.color-red {
  background-color:#f2dede !important;
}

.navbar.navbar-static-top {
  margin-bottom: 0;
}

.page-heading.no-breadcrumb {
  padding-bottom: 10px;
}

.text-middle {
  vertical-align: middle !important;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-content.ibox-content {
    padding: 0;
}

.chosen-container-single,
.chosen-search-input,
.search-field {
  width: 100% !important;
}

.chosen-container-single:not(.chosen-container-active) .chosen-search-input {
  display: none;
}

.col-chk {
  width: 10px;
}

.nav.navbar-top-links a {
  color: #999c9e;
}

.prepend-procent {
  line-height: 30px;
  font-size: 16px;
  padding: 0;
}

.attachment-form-field {
  border: none !important;
  padding: 0;
}

.dataTables_wrapper table th {
  text-align: center;
}

#orderscheduleform-sort-container {
  width: 200px;
  margin-right: 10px;
}

#orderscheduleform-vendor-container {
  width: 200px;
  margin-right: 10px;
}

#orderscheduleform-invoiceable-container {
  margin-bottom: 20px;
}

#orderform-sort-container {
}

#orderform-vendor-container {
}

#order-page-refresh-offers {
}

#orderform-offers-filters {
  margin-bottom: 10px;
}

#categories-tree .dd-empty {
  display: none;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  background: #f2dede;
  border-color: #ebccd1;
  color: #676a6c ;
}

.service-table th {
  text-align: center;
}

#service-params-table .service-primary-row td:not(:first-child) {
  background: #e7eaec;
}

.service-table .service-primary-row td:first-child {
  font-weight: bold;
}

#service-params-table .param-overrides-price,
#service-params-table .param-changes-price {
  text-align: center;
}

#breadcrumb {
  margin-top: 10px;
}

.delete-order-attachment {
  padding: 0 !important;
  line-height: 0;
  font-size: 13px;
}

.delete-order-attachment .fa {
  line-height: 0 !important;
}

.delete-order-comment .fa {
  line-height: 0 !important;
}

.delete-order-comment {
  padding: 0 !important;
  line-height: 0;
  font-size: 13px;
}

.edit-order-comment .fa {
  line-height: 0 !important;
}

.edit-order-comment {
  padding: 0 !important;
  line-height: 0;
  font-size: 13px;
}

.serviceWorksiteAssocForm-priority-info {
  margin-bottom: 20px;
}


/*******************
 *
 * DATEPICKER
 *
*******************/

.datepicker.dropdown-menu {
  box-shadow: none!important;
  border-color: #e5e6e7;
}

.datepicker-days {
  padding: 20px;
}

.datepicker-days .today {
  background: rgba(26, 179, 148, 0.3)!important;
}

.datepicker-days .active {
  background: rgba(41, 56, 70, 0.9)!important;
}

/*******************
 *
 * FORM ERRORS
 *
*******************/

label.required:after {
  content: "*";
  color: #ed5565;
}

.has-error .control-label {
  color: #676a6c;
}

/*******************
 *
 * LOGO
 *
*******************/

.profile-element .logo-main img {
  width: 170px;
}

#login-box-panel .logo-main img {
  width: 250px;
}

.additional-label-text {
  display: inline-block;
}

/*******************
 *
 * Vendor Form
 *
*******************/

#vendorform-type-field input[type="radio"]+label{
  padding-right: 15px;
  padding-left: 5px;
}

#vendor-capacities-form .form-group {
  margin-bottom: 0;
}

#vendor-capacities-form .capacity-holder {
  width: 200px;
}

#orderform-status-field {
  margin-bottom: 15px;
}

/*******************
 *
 * Order Invoice
 *
*******************/

.orderinvoice-payment-container {
  margin-top: 20px;
}

#orderinvoice-preview-proforma-btn {
  margin-right: 10px !important;
}

#orderinvoice-payment-status .chosen-container,
#orderinvoice-payment-type .chosen-container {
  min-width: 120px;
}

#order-page #order-invoice-section .ibox-content {
  overflow: visible;
}

#orderinvoice-success-message,
#orderinvoice-error-message {
  margin-bottom: 20px;
}

#orderclientform-modal .form-group {
  margin-bottom: 10px;
}

#orderclientform-modal .alert {
  margin-bottom: 10px;
}

#orderclient-search-button {
    top: 0;
    padding: 3px 20px;
}

/*******************
 *
 * Categories Tree
 *
*******************/

.category-item-actions {
  display: inline-block;
  float: right;
}

.category-item-actions i:first-child {
  margin-right: 10px;
}

#categoryform-add-button {
  margin-top: 24px;
}

#categories-form {
  margin-bottom: 20px;
}

.dd-name {
  padding: 10px 20px;
  margin: 5px 0;
  color: #333;
  text-decoration: none;
  border: 1px solid #e7eaec;
  background: #f5f5f5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
}

.dd-name:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

/**
 * Add box shadow to the entire item when dragged
 */
.dd-dragel .dd-name {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.dd-handle {
  width: 80%;
  padding: 0;
  display: inline-block;
  margin: 0;
  border: 0;
  background-color: transparent;
}

.dd-handle:hover {
  background: transparent;
}

/**
 * Remove the box shadow from the .dd-handle item when dragged
 */
.dd-dragel .dd-handle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/**
 * Hide the collapse button for the moment
 */
.dd-item > button {
  display: none;
}


/*******************
 *
 * My profile
 *
*******************/

.user-profile-form select {
  padding-left: 7px;
}

/*******************
 *
 * ORDERS
 *
*******************/

.orderLink {
  font-weight: bold;
  color: #1ab394;
}

.orderLink:hover,
.orderLink:focus {
  color: #18a689;
}

/*******************
 *
 * Service configuration
 *
*******************/

#serviceconfig-modal .table-action-col {
  width: 100px;
}

#serviceconfig-modal .table-action-col .btn {
  padding: 1px 5px;
}

#serviceconfig-params-form .form-group .form-inline .form-group {
  margin-left: 0;
  margin-right: 0;
}

#serviceconfig-params-grid .info {
  font-size: 11px;
  padding-top: 2px;
  font-weight: bold;
}

@media (min-width:768px) {

  #serviceconfig-optionName-field {
    width: 160px;
  }

  #serviceconfig-optionValue-field {
    width: 100px;
  }

  #serviceconfig-optionPercent-field {
    width: 100px;
  }

  #orderscheduleform-modal .modal-dialog {
    width: 750px !important;
  }
}

#serviceconfig-options-emptylist {
  display: none;
  /*margin-top: 20px;*/
  /*margin-bottom: 20px;*/
}

#serviceconfig-options-grid {
  margin-top: 10px;
}

/*******************
 *
 * Price Rule Form
 *
*******************/

label[for=price_rule_form_status_0] {
  margin-right: 10px;
}

select.form-control option:first-child {
  color: #999 !important; /* Remove Extra Whitespace */
}

.navbar-form-custom {
  padding: 13px 0 0 0;
}

.page-grid-filters {
    padding: 10px 20px;
}

.page-heading h2 {
  margin: 10px 0 2px  0;
}

.grid-filters .grid-filters-row {
  padding: 0 0 5px 0;
}

.form-control, .single-line,
.chosen-container-single .chosen-single {
  font-size: 13px;
  height: 30px!important;
  border: solid 1px #e5e6e7;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding-bottom: 0;
  padding-top: 0;
}

textarea.form-control {
    height: auto!important;
}

textarea.form-control.description-field {
  height: 100px !important;
}

.chosen-container-single .chosen-single span {
    line-height: 28px;
}

.chosen-container .chosen-results {
    padding: 0;
}

.chosen-container .chosen-results li {
    padding: 0 6px;
}

.chosen-container-single .chosen-single div b {
    background-position: 0 5px;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 5px;
}

table.dataTable tbody tr td.dataTables_empty {
    text-align: left;
}

.chosen-container {
  width: 100%!important;
}

#order-reschedule-form input[type=checkbox] {
  top: auto;
}

#order-reallocation-form input[type=checkbox] {
  top: auto;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #1ab394;
}

.daterangepicker .input-mini.active {
  border: solid 1px #1ab394;
}

textarea {
    resize: none;
}

.help-block {
  margin: 2px 0 0 0;
}

.inmodal .modal-header {
    padding: 10px 15px;
}

.page-grid-title .page-grid-add-btn,
.page-title-button {
  margin: 0;
  position: relative;
  top: 10px;
}

.page-title-button {
  margin-right: 10px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  line-height: 12px;
  padding: 6px 8px;
  vertical-align: middle;
}

table.dataTable tbody tr td {
  padding-top: 6px;
}

.dataTables_paginate a.current {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #fff;
}

.dataTables_paginate a.current:hover {
  background-color: #18a689;
  border-color: #18a689;
}

.alert {
  margin: 5px 0 5px 0;;
  padding: 5px;
  border-radius: 0px;
}

.daterangepicker td.start-date {
  border-radius: 4px;
}

.datepicker-days .day {
  min-width: 32px;
}

.datepicker-days .today {
  background-color: #1ab394!important;
  color: #fff!important;
  min-width: 32px;
}

.align-btn {
  float: right;
  margin: 0!important;
  position: relative;
  top: -6px;
}

#order-page .ibox-content {
  overflow: hidden;
}

.individual-listing-page .no-margin-bottom .form-group {
  margin-bottom: 0;
}

.individual-listing-page .no-margin-bottom .form-group p {
  padding: 8px 0 0 0;
}

.body-small .navbar-top-links li:last-child {
	margin-right: 40px;
}

.form-control-static {
	min-height: auto;
	padding-top: 0;
	padding-bottom: 0;
}

.inmodal .modal-title {
	font-size: 24px;
}


.help-block {
    font-size: 12px;
}

.btn {
    padding: 3px 12px;
}

.form-group {
    margin-bottom: 10px;
}


.dataTable td a.btn {
    font-size: 11px;
    padding: 0 10px;
}

.individual-listing-page .no-margin-bottom .form-group p {
    padding: 0;
    margin-bottom: 5px;
}

.individual-listing-page .form-horizontal .control-label {
    padding-top: 0;
}

#orderform-status-field {
    margin-bottom: 0;
}

#order-status-form .form-group {
    padding-bottom: 10px;
    overflow: hidden;
}

#global-search-container {
  display: inline-block;
}

#orderform-total-error {
  margin-left: 0;
}

#orderform-progress {
  margin-top: 10px;
  margin-bottom: 10px;
}

#orderform-progress .progress {
  margin-bottom: 5px;
}

#orderform-progress .pr-10 {
  margin-right: 10px;
}

.small-field {
  width: 70px;
  display: inline-block;
}

.order-attachment-form-ibox {
  border-top: none;
  padding-top: 0;
}
.order-attachment-gridpanel-ibox {
  padding-bottom: 0;
}

#order-attachment-emptylist {
  padding-bottom:15px;
}

.order-status-close {
  color: #ed5565;
}

.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

#notificationform-body-field {
  height: 100px !important;
}

.form-additional-text {
  font-style: italic;
}

.editable-click,
a.editable-click,
a.editable-click:hover {
  border: 0;
  color: #676a6c;
  padding: 0 !important;
}

.editable-click:after {
  content: "\f040";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  margin-left: 5px;
}

.editable-click.editable-disabled:after {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  margin-left: 5px;
}

.editable-click.editable-disabled,
a.editable-click.editable-disabled,
a.editable-click.editable-disabled:hover {
  cursor: auto !important;
  outline: none;
}

.editable-click.editable-disabled,
.editable-disabled {
  outline: none;
}

.editable-container.editable-inline {
  padding-left: 15px;
}

.editable-buttons {
  margin-top: 2px;
}

#showroom-name .editable-input {
  width: 100%;
}

/*******************
 *
 * MEDIA QUERIES
 *
*******************/



@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: left;
  }

  #orderform-vendor-container {
    width: 160px;
  }
}

@media (max-width: 1024px) {
  .grid-filters .grid-filters-row {
    padding: 0;
  }

  #order-page .form-group.grid-filters-row .col-lg-3 {
    display: none!important;
  }

  #order-page .no-margin-bottom .form-group p {
    padding: 0;
  }

}

@media (max-width: 768px) {

    .grid-filter-field {
        padding-top: 0;
        padding-bottom: 10px;
  }

  .dataTables_length {
    float: none!important;
    text-align: center;
    display: block;
  }

  .dataTables_length label {
    font-size: 12px;
  }

  .dataTables_length select {
    width: 48px;
    margin: 0;
    padding: 0;
  }

  .dataTables_info {
    display: none;
  }

  .dataTables_paginate {
    text-align: center;
    padding: 15px 0 10px 0;
  }

  .navbar-form-custom {
    display: block !important;
    width: 100%;
  }

  .navbar-header {
    float: none;
  }

  .navbar-top-links {
    padding-left: 14px;
  }

}

@media (max-width: 1024px) {
  .grid-filters .grid-filters-row {
    padding: 0;
  }

  #order-page .form-group.grid-filters-row .col-lg-3 {
    display: none!important;
  }

  #order-page .no-margin-bottom .form-group p {
    padding: 0;
  }

}

@media (max-width: 768px) {
    .grid-filter-field {
        padding-top: 0;
        padding-bottom: 10px;
  }

  .chosen-select.multiple-select {
      height: 40px!important;
  }
  .chosen-select.multiple-select option:first-child {
      display: none;
  }

  #order-status-form button[type=submit],
  #orderform-save-button {
      margin-top: 10px;
  }

  .dataTables_length {
    float: none!important;
    text-align: center;
    display: block;
  }

  .dataTables_length label {
    font-size: 12px;
  }

  .dataTables_length select {
    width: 48px;
    margin: 0;
    padding: 0;
  }

  .dataTables_info {
    display: none;
  }

  .dataTables_paginate {
    text-align: center;
    padding: 15px 0 10px 0;
  }

}

/**
Error Pages
 */

.error-page {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f3f3f4;
}

.error-page-body {
  overflow: hidden;
  position: relative;
  width: 100%;
}

/**
 * External pages
 */

.external-page {
  min-height: 100vh;
  background-color: #f3f3f4;
}

.external-page-body {
  height: 100%;
  width: 100%;

}

/**
 * Payment pages
 */

.external-page .payment-button {
  margin-top: 20px;
}

#payment-payu-page {
  background: transparent;
}

.external-page .ibox-content.sk-loading:after {
  background: transparent;
}

.external-page .payment-description {
  margin-top: 32px;
}

.external-page .payment-middle-box {
  max-width: 600px;
  padding-bottom: 50px;
}

.external-page .payment-order-details {
  margin-top: 32px;
}

.external-page .payment-logo-container {
  text-align: center;
}

.external-page .payment-title {
  font-weight: bold;
}

.external-page .payment-logo-container .payment-logo {
  width: 250px;
  margin-bottom: -20px;
}


#order-history-form-modal > .modal-dialog{
  width:auto;
  margin-left: auto;
  margin-right: auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

#order-reallocation-history-form-modal > .modal-dialog{
  width:auto;
  margin-left: auto;
  margin-right: auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.status_days {
  width: 25%;
  display: inline;
  margin-left: 2%;
  margin-right: 2%;
  /*float: right;*/
  vertical-align: middle;
}

.appointment_range{
  width: 1300px !important;
}

.order-schedule-total-container {
  padding-left: 20px;
  width: 300px;
}

.orderschedule-offers-actions {
  margin-bottom: 20px;
}

#logs-request,
#logs-response,
#logs-sap-request,
#logs-sap-response,
#logs-payu {
  word-wrap: break-word;
}

@media (max-width: 767px) {
  .external-page .payment-middle-box {
    max-width: 500px;
  }

  .external-page .payment-order-details .row {
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .external-page .payment-middle-box {
    max-width: 400px;
  }
}

@media (max-width: 467px) {

  .order-filters-checkboxes label {
    width: 90%;
    vertical-align: top;
  }

  .external-page .payment-middle-box {
    max-width: 350px;
  }

  .external-page .payment-logo-container .payment-logo {
    width: 150px;
  }

  .external-page .payment-title {
    padding-left: 0;
    padding-right: 0;
    font-size: 17px;
  }

  .payment-button {
    width: 100%;
  }

}


/**
 * CSS for print
 */

@page { size: auto;  margin: 20px 0 0 0; }

@media print {

  .sf-toolbar,
  .sf-toolbarreset {
    display: none !important;
  }
}